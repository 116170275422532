import { useDomain } from '../../../../context/domain'

import { ReactComponent as AssenLogo } from '../../../../../svg/area/assen/logo.svg'
import { ReactComponent as BeezyLogo } from '../../../../../svg/area/beezy/logo.svg'
import { ReactComponent as BuurtBoeket } from '../../../../../svg/area/buurtboeket/logo.svg'
import { ReactComponent as DenBoschLogo } from '../../../../../svg/area/denbosch/logo.svg'
import { ReactComponent as GeleenLogo } from '../../../../../svg/area/geleen/logo.svg'
import { ReactComponent as GroningenLogo } from '../../../../../svg/area/groningen/logo.svg'
import { ReactComponent as HilversumLogo } from '../../../../../svg/area/hilversum/logo.svg'
import { ReactComponent as SteenwijkerlandLogo } from '../../../../../svg/area/steenwijkerland/logo.svg'
import { ReactComponent as TerneuzenLogo } from '../../../../../svg/area/terneuzen/logo.svg'
import { ReactComponent as ZeelandLogo } from '../../../../../svg/area/zeeland/logo.svg'
import { ReactComponent as ZwolleLogo } from '../../../../../svg/area/zwolle/logo.svg'
import { ReactComponent as Zupr } from '../../../../../svg/logo.svg'

const logos = {
    nederland: Zupr, // Zupr Nederland
    groningen: GroningenLogo, // Groningen
    hilversum: HilversumLogo, // Hilversum
    denbosch: DenBoschLogo, // Den Bosch
    terneuzen: TerneuzenLogo, // Terneuzen
    zwolle: ZwolleLogo, // Zwolle
    geleen: GeleenLogo, // Geleen
    assen: AssenLogo, // Assen
    steenwijkerland: SteenwijkerlandLogo, // Shop in de kop
    zeeland: ZeelandLogo, // Warenhuis zeeland
    beezy: BeezyLogo, // Beezy
    bloemisten: BuurtBoeket,
}

const Logo = () => {
    const { slug } = useDomain()
    const Logo = logos[slug] || logos.nederland
    return <Logo />
}

export default Logo
